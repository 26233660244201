/**
 *
 * A helper function to build out and populate brackets
 * based on an array of entrants or (teams or players).
 * @param {avatar, name} entrants
 * @returns { rounds, winner };
 */
export const calculateBrackets = (entrants) => {
  const rounds = [];
  let remainingEntrants = [...entrants];
  let winner;

  /**
   * Recursive function that build the structure of
   * the brackets (rounds and matches) the length of
   * the array of entrants provided.
   *
   * @param { avatar: img-src; name: string } currEnts
   *
   */
  const calculateRounds = (entrantsLength) => {
    if (entrantsLength % 2) return;

    if (entrantsLength / 2) {
      const matches = [];

      for (let i = 0; i < entrantsLength / 2; i++) {
        matches.push([{}, {}]);
      }

      const round = { matches };
      rounds.push(round);

      calculateRounds(entrantsLength / 2);
    }
  };

  /**
   *
   * First build the structure - rows, with matches.
   * So we know how many rounds and matches there are.
   *
   */
  calculateRounds(entrants.length);

  /**
   *
   * Based on the array created by calculateRounds and stored in 'rows',
   * populate the matches with the correct teams.
   *
   */
  rounds.reduce((acc, curr, index) => {
    const populatedMatches = curr.matches.reduce((ac, cr, i) => {
      const pointer = i * 2;
      const player1 = remainingEntrants[pointer];
      const player2 = remainingEntrants[pointer + 1];

      ac.push([{ ...player1 }, { ...player2 }]);

      return ac;
    }, []);

    /**
     *
     * Remove entrants with no wins in this round.
     * They should not appear in the next round.
     * Store winner if there is one.
     *
     */
    remainingEntrants = entrants.reduce((ac, cr) => {
      if (cr.wins) {
        if (cr.wins.includes(index + 1)) {
          ac.push(cr);
          if (index + 1 === rounds.length) {
            winner = cr;
          }
        }
      }

      return ac;
    }, []);

    curr["matches"] = populatedMatches;
    curr["title"] =
      index === rounds.length - 2
        ? "SemiFinals"
        : index === rounds.length - 1
        ? "Finals"
        : `Round ${index + 1}`;

    acc.push(curr);

    return acc;
  }, []);

  return { rounds, winner };
};
