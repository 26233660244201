import React, { createRef, useEffect, useState } from "react";
import classnames from "classnames";
import Select from "react-select";
import useDragScroll from "use-drag-scroll";

import Match from "./tournament-match";
import { TournamentAvatarList } from "..";

import { calculateBrackets } from "./helpers";

import "./tournament-bracket.scss";

/**
 *
 * Hard coded values for zoom levels and column widths.
 * Decides with of column based on zoom level selection.
 * They should have the same length.
 *
 */
const ZOOM_LEVELS = ["100%", "125%", "150%", "175%"];
const COL_WIDTHS = ["180px", "220px", "260px", "300px"];

/**
 *
 * Hard coded date values.
 * @todo: These should be retrieved from the API
 *   and stored in the rounds object.
 *
 */
const ROUND_DATES = [
  "March 8, 20:00",
  "March 9, 20:00",
  "March 10, 20:00",
  "March 11, 20:00",
  "March 12, 20:00",
];

const TournamentBracket = ({ data }) => {
  const { entrants } = data || {};
  // The main row - stored so we can reference its height or scroll it based on bracket focus selected.
  const bracketRow = createRef();
  // Controlled by the first dropdown in the UI. Dims elements, based on current win/loss status.
  const [filtered, setFiltered] = useState(undefined);
  // Used to open and close the view to reveal more of the brackets.
  const [bracketHeight, setBracketHeight] = useState(undefined);
  // Controlled by the full screen button in the UI.
  const [isFullView, setIsFullView] = useState(undefined);
  // Cycles through zoomlevels when '+' or '-' is clicked in the UI.
  const [zoomLevel, setZoomLevel] = useState(0);

  const [roundOpts, setRoundOpts] = useState(undefined);

  /**
   * Setup draggable container.
   * This is applied to `tournament-bracket__row`
   */
  useDragScroll({
    sliderRef: bracketRow,
    reliants: [zoomLevel],
  });

  /**
   *
   * Based on the amount of entrants,
   *   create the brackets and then populate them.
   *
   */
  const { rounds, winner } = entrants ? calculateBrackets(entrants) : {};

  /**
   * Passed to the full view button.
   * Opens/closes view.
   */
  const handleViewToggleClick = () => {
    setBracketHeight(bracketRow.current.clientHeight);
    setIsFullView(!isFullView);
  };

  /**
   * Passed to the zoom buttons.
   * Expands/shrinks the column width.
   * @param {'up' | 'down'} dir
   */
  const handleZoomClick = (dir) => {
    let updatedZoomLevel = zoomLevel;

    if (dir === "down" && zoomLevel > 0) {
      updatedZoomLevel--;
    } else if (dir === "up" && zoomLevel < ZOOM_LEVELS.length - 1) {
      updatedZoomLevel++;
    }

    setZoomLevel(updatedZoomLevel);
  };

  /**
   * Dims elements based on winner/loser status.
   * @param {changeEvent} e
   */
  const handleHighlightChange = (e) => {
    const { value } = e;
    setFiltered(value);
  };

  /**
   * Scrolls bracket row based on round selected.
   * @param {changeEvent} e
   */
  const handleBracketFocus = (e) => {
    const { value } = e;
    const targetScrollTo = value
      ? parseInt(COL_WIDTHS[zoomLevel], 10) * value
      : 0;
    const currScrollTo = bracketRow.current.scrollLeft;
    const delta = targetScrollTo - currScrollTo;

    bracketRow.current.scroll({
      top: 0,
      left: targetScrollTo,
      behavior: "smooth",
    });
  };

  /**
   * Populates drop down options based on rounds.
   */
  useEffect(() => {
    if (!roundOpts && rounds) {
      const opts = rounds.reduce((acc, curr, index) => {
        acc.push({ label: curr.title, value: index });
        return acc;
      }, []);
      // Append hard-coded first and last values.
      opts.unshift({ label: "Full bracket", value: "" });
      opts.push({ label: "Winner", value: rounds.length });

      setRoundOpts(opts);
    }
  }, [rounds, roundOpts]);

  return (
    <div className="tournament-bracket">
      <div className="tournament-bracket__header">
        {/* 
          List of teams.
         */}
        {entrants && (
          <>
            <TournamentAvatarList avatars={entrants} />
            <span className="tournament-bracket__entrant-count">{`${entrants.length} teams`}</span>
          </>
        )}

        {/* 
          Bracket UI
        */}
        {/* 
          Filter
        */}
        <div className="tournament-bracket__controls">
          <div className="tournament-bracket__ui-container tournament-bracket__ui-container--select">
            <Select
              className="react-select"
              classNamePrefix="react-select"
              onChange={handleHighlightChange}
              options={[
                { value: "", label: "All teams" },
                { value: "winners", label: "Winners" },
                { value: "losers", label: "Losers" },
              ]}
              placeholder="All teams"
            />
          </div>
          <div className="tournament-bracket__ui-container tournament-bracket__ui-container--select">
            <Select
              className="react-select"
              classNamePrefix="react-select"
              onChange={handleBracketFocus}
              options={roundOpts}
              placeholder="Full bracket"
            />
          </div>

          {/* 
            Zoom
          */}
          <div className="tournament-bracket__ui-container tournament-bracket__ui-container--zoom">
            <div className="tournament-bracket__ui tournament-bracket__zoom-container">
              {/* Minus */}
              <button
                disabled={zoomLevel <= 0}
                onClick={() => handleZoomClick("down")}
              >
                <svg
                  width="12"
                  height="2"
                  viewBox="0 0 12 2"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1H11"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
              {/* Current Level */}
              <span>{ZOOM_LEVELS[zoomLevel]}</span>
              {/* Plus */}
              <button
                disabled={zoomLevel >= ZOOM_LEVELS.length - 1}
                onClick={() => handleZoomClick("up")}
              >
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 6H11"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M6 1L6 11"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>

            {/*
              Expand Toggle
            */}
            <button
              className={classnames(
                "tournament-bracket__ui tournament-bracket__full-view-toggle",
                {
                  "tournament-bracket__full-view-toggle--open": isFullView,
                }
              )}
              onClick={handleViewToggleClick}
            >
              <svg viewBox="10 10 16 16" aria-hidden="true">
                <path d="M10 16h2v-4h4v-2h-6v6z" />
                <path d="M20 10v2h4v4h2v-6h-6z" />
                <path d="M24 24h-4v2h6v-6h-2v4z" />
                <path d="M12 20h-2v6h6v-2h-4v-4z" />
              </svg>
            </button>
          </div>
        </div>
      </div>

      {/* 
        Bracket content.
      */}
      <div
        className="tournament-bracket__container"
        style={isFullView ? { height: bracketHeight } : {}}
      >
        <div
          id="bracket-row"
          ref={bracketRow}
          className="tournament-bracket__row"
        >
          {rounds &&
            rounds.map((round, index) => (
              <div
                key={index}
                className="tournament-bracket__col"
                style={
                  zoomLevel
                    ? {
                        minWidth: COL_WIDTHS[zoomLevel],
                        manWidth: COL_WIDTHS[zoomLevel],
                      }
                    : {}
                }
              >
                <TournamentRound
                  key={index}
                  data={round}
                  index={index}
                  isSemis={index === rounds.length - 2}
                  isFinals={index === rounds.length - 1}
                  winner={winner}
                  filtered={filtered}
                />
              </div>
            ))}

          <div
            className="tournament-bracket__col"
            style={
              zoomLevel
                ? {
                    minWidth: COL_WIDTHS[zoomLevel],
                    manWidth: COL_WIDTHS[zoomLevel],
                  }
                : {}
            }
          >
            <TournamentRound
              winner={winner}
              index={rounds && rounds.length}
              isWinnerRound
              filtered={filtered}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 *
 * Renders the Round
 *
 */
const TournamentRound = ({
  data,
  filtered,
  index,
  isFinals,
  isWinnerRound,
  winner,
}) => {
  const { matches, title } = data || {};
  const roundTitle = isWinnerRound ? "Winner" : title;
  /**
   *
   * Set the round date -
   * if it is the winner column it will have the winner's name or 'TBD'.
   *
   */
  const roundDate =
    isWinnerRound && winner
      ? winner.name
        ? winner.name
        : "TBD"
      : ROUND_DATES[index]
      ? ROUND_DATES[index]
      : "TBD";

  return (
    <div className="tournament-bracket__round">
      <div className="tournament-bracket__round-header">
        <h4 className="tournament-bracket__round-title">{roundTitle}</h4>
        <span className="tournament-bracket__round-date">{roundDate}</span>
      </div>
      <div className="tournament-bracket__round-matches">
        {matches ? (
          matches.map((match, i) => (
            <Match
              key={i}
              entrants={match}
              filtered={filtered}
              roundIndex={index}
              isFinals={isFinals}
              isWinnerRound={isWinnerRound}
              winner={winner}
            />
          ))
        ) : (
          <Match
            filtered={filtered}
            isWinnerRound={isWinnerRound}
            winner={winner}
          />
        )}
      </div>
    </div>
  );
};

export default TournamentBracket;
