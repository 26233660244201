import React from "react";
import classnames from "classnames";

/**
 *
 * Returns a match jsx object
 * to be used in a tournament bracket round.
 *
 * @param {
 *   entrants: {avatar: string, name: string, wins: number[]},
 *   filtered: 'winners' | 'losers' | undefined
 *   isFinals: boolean
 *   isWinnerRound: boolean
 *   roundIndex: number,
 *   winner: entrant
 * } props
 * @returns
 */
const TournamentMatch = (props) => {
  const {
    entrants = [{}, {}],
    filtered,
    isFinals,
    isWinnerRound,
    roundIndex,
    winner,
  } = props;
  let isCompleted = false;

  entrants.forEach((entrant) => {
    if (entrant.wins && entrant.wins.includes(roundIndex + 1)) {
      isCompleted = true;
    }
  });

  return (
    <div className="tournament-match">
      <div className="tournament-match__entrants">
        {isWinnerRound ? (
          /**
           *
           * Winning round only has one entrant.
           * Ignore 'entrant' and only populate with 'winner' data - if available.
           *
           */
          <div
            className={classnames("tournament-match__entrant", {
              "tournament-match__entrant--dimmed":
                (winner && filtered === "losers") || (!winner && filtered),
            })}
          >
            <div className="tournament-match__entrant-avatar">
              {winner && winner.avatar && (
                <img src={winner.avatar} alt={winner.name} />
              )}
            </div>
            <span className="tournament-match__entrant-name">
              {winner && winner.name}
            </span>
          </div>
        ) : (
          /**
           *
           * Loop through the twon entrants in each match to populate match.
           *
           */
          entrants.map((entrant, index) => {
            const entrantIsUndefined = !entrant.name && !entrant.avatar;
            const isMatchWinner =
              entrant.wins && entrant.wins.includes(roundIndex + 1);
            const isMatchLoser =
              (entrant.name && entrant.avatar && !entrant.wins) ||
              (entrant.wins && !entrant.wins.includes(roundIndex + 1));

            return (
              <div
                className={classnames("tournament-match__entrant", {
                  "tournament-match__entrant--dimmed":
                    (isMatchWinner && filtered === "losers") ||
                    (isMatchLoser && filtered === "winners") ||
                    (!isCompleted && filtered) ||
                    (entrantIsUndefined && filtered),
                })}
                key={index}
              >
                <div className="tournament-match__entrant-avatar">
                  {entrant.avatar && (
                    <img src={entrant.avatar} alt={entrant.name} />
                  )}
                </div>
                <span className="tournament-match__entrant-name">
                  {entrant.name}
                </span>
              </div>
            );
          })
        )}
      </div>

      {/* 
        Curved line connecting bracket.
      */}
      {!isWinnerRound && !isFinals && (
        <span
          className={classnames("tournament-match__connector", {
            "tournament-match__connector--complete": isCompleted,
          })}
        >
          <span className="arch arch--top" />
          <span className="vertical-line" />
          <span className="arch arch--bottom" />
        </span>
      )}

      {/* 
        Straign line connecting Finals to Winner.
      */}
      {isFinals && (
        <span
          className={classnames(
            "tournament-match__connector tournament-match__connector--finals",
            {
              "tournament-match__connector--complete": winner,
            }
          )}
        />
      )}
    </div>
  );
};

export default TournamentMatch;
